<template>
  <a-card :bordered="false">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="user_nick_name" slot-scope="text, record">
        <a :href="'/clients/detail?id=' + record.user_id">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goShowOrder(record)">查看</a>
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { orderList } from '@/api/order'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true
  },
  {
    title: '订单号',
    dataIndex: 'ordernum'
  },
  {
    title: '日期',
    dataIndex: 'create_time',
    sorter: true,
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '客户名称',
    dataIndex: 'user.nick_name',
    scopedSlots: { customRender: 'user_nick_name' }
  },
  {
    title: '付款方式',
    dataIndex: 'payment_method'
  },
  {
    title: '金额',
    dataIndex: 'amount',
    sorter: true
  },
  {
    title: '支付状态',
    dataIndex: 'pay_status',
    sorter: true
  },
  {
    title: '状态',
    dataIndex: 'status'
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ClientOrder',
  props: {
    clientId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      columns,
      // 查询参数
      queryParam: {},
      order_status_type: [],
      pay_status_type: [],
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
  },
  mounted() {
    this.queryParam = { user_id: this.clientId }
    this.fetch(this.queryParam)
  },
  watch: {
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      // 带上查询参数
      const queryParam = this.queryParam
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        orderList(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        this.order_status_type = data.order_status_type
        this.pay_status_type = data.pay_status_type
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    goShowOrder(record) {
      this.$router.push({ 'name': 'OrderEdit', query: { id: record.id } })
    }
  }
}
</script>
