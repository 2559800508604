<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-tabs default-active-key="2">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="solution" />
          资料
        </span>
        <client-profile :clientId="clientId" :info="info" />
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="contacts" />
          学员
        </span>
        <contacts :clientId="clientId" :student="info.student" @refreshData="contactsRefreshData" />
      </a-tab-pane>
      <a-tab-pane key="3">
        <span slot="tab">
          <a-icon type="file-sync" />
          订单
        </span>
        <order :clientId="clientId" />
      </a-tab-pane>
      <a-tab-pane key="4">
        <span slot="tab">
          <a-icon type="money-collect" />
          交易流水
        </span>
        Tab 3
      </a-tab-pane>
      <a-tab-pane key="5">
        <span slot="tab">
          <a-icon type="file-exclamation" />
          工单
        </span>
        Tab 3
      </a-tab-pane>
      <a-tab-pane key="6">
        <span slot="tab">
          <a-icon type="mail" />
          邮件日志
        </span>
        <client-email :clientId="clientId" />
      </a-tab-pane>
      <a-tab-pane key="7">
        <span slot="tab">
          <a-icon type="mobile" />
          短信日志
        </span>
        <client-sms :clientId="clientId" />
      </a-tab-pane>
      <a-tab-pane key="8">
        <span slot="tab">
          <a-icon type="paper-clip" />
          活动日志
        </span>
        <client-active-log :clientId="clientId" />
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import { userInfo } from '@/api/user'
import ClientProfile from './components/Profile'
import Contacts from './components/Contacts'
import Order from './components/Order'
import ClientEmail from './components/ClientEmail'
import ClientSms from './components/ClientSms'
import ClientActiveLog from './components/ClientActiveLog'

export default {
  name: 'AddRule',
  components: {
    ClientProfile,
    Contacts,
    Order,
    ClientEmail,
    ClientSms,
    ClientActiveLog
  },
  data() {
    return {
      description: '用户详情',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      clientId: 0,
      queryData: {
      },
      // form
      form: this.$form.createForm(this),
      submitLoading: false,
      info: {}
    }
  },
  created() {
  },
  mounted() {
    const clientId = parseInt(this.$route.query.id)
    if (clientId === undefined) {
      this.$router.back(-1)
    }
    this.loadInfo(clientId)
    this.clientId = clientId
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        userInfo({ id: id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.info = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    },
    contactsRefreshData(e) {
      this.loadInfo(this.clientId)
    }
  }
}
</script>
