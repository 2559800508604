<template>
  <div>
    <a-divider orientation="right">
      <div>
        <label>学员：</label>
        <a-select default-value="add" v-model="contactsId" style="width: 120px" @change="handleContactsChange">
          <a-select-option v-for="(option, index) in student" :key="index" :value="option.id">{{ option.nick_name }}
          </a-select-option>
          <a-select-option value="add">添加</a-select-option>
        </a-select>
      </div>
    </a-divider>
    <a-form @submit="handleSubmit" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="10" :md="24" :sm="24">
          <a-form-item label="昵称" :labelCol="{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-input v-decorator="[
              'nick_name',
              {
                rules: [{ required: true, message: '昵称' }],
                initialValue: info.nick_name
              },
            ]" placeholder="昵称" />
          </a-form-item>
        </a-col>
        <a-col :lg="14" :md="24" :sm="24">
          <a-form-item label="性别" :labelCol="{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-select v-decorator="[
              'sex',
              {
                rules: [{ required: true, message: '请选择性别' }],
                initialValue: info.sex
              }
            ]" placeholder="选择性别">
              <a-select-option value="男">男</a-select-option>
              <a-select-option value="女">女</a-select-option>
              <a-select-option value="未知">未知</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- 学员阶段 -->
      <a-form-item label="学员阶段" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'stage',
          {
            rules: [{ required: true, message: '需要选择学员阶段' }],
            initialValue: info.stage
          }
        ]">
          <a-select-option v-for="option in student_stage" :key="option" :value="option">{{ option }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 需要提高的学科 -->
      <a-form-item label="提高科目" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select mode="tags" style="width: 100%" :token-separators="[',']" @change="coachSubjectHandleChange"
          v-decorator="[
            'coach_subject',
            {
              initialValue: info.coach_subject_arr
            }
          ]">
          <a-select-option v-for="option in coach_subject" :key="option" :value="option">{{ option }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="学员描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="2" placeholder="学员基本情况介绍" v-decorator="[
          'desc',
          {
            initialValue: info.desc
          }
        ]" />
      </a-form-item>
      <a-form-item label="对教员的要求" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="2" placeholder="对教员的要求" v-decorator="[
          'require_desc',
          {
            initialValue: info.require_desc
          }
        ]" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-popconfirm v-if="deleteBtn" placement="rightBottom" title="确定要删除该学员吗？" @confirm="handleDelStudent">
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a-button :loading="delLoading" type="danger" style="margin-left: 8px">删除</a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { userAdditional, userStudentCreate, userStudentSave, userStudentDelete } from '@/api/user'
export default {
  name: 'ClientContacts',
  props: {
    clientId: {
      type: Number,
      default: undefined
    },
    student: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      form: this.$form.createForm(this),
      submitLoading: false,
      delLoading: false,
      info: {},
      student_stage: [],
      coach_subject: [],
      contactsId: 'add',
      deleteBtn: false
    }
  },
  created() {
    this.loadingAdditional()
  },
  mounted() {
  },
  watch: {
    clientId(val) {
      console.log(val)
    },
    student: {
      handler: function (val, oldval) {
        // 检测到student值的变化，将页面修改
        this.contactsId = 'add'
        this.handleContactsChange('add')
        console.log(val)
      },
      deep: true
    }
  },
  methods: {
    handleContactsChange(e) {
      // 切换学员选择器，修改显示数据
      if (e === 'add') {
        this.info = {
          coach_subject: [],
          desc: '',
          nick_name: '',
          notes: '',
          sex: '未知',
          require_desc: '',
          stage: ''
        }
        this.deleteBtn = false
      } else {
        const _this = this
        this.student.forEach(function (info, index) {
          if (info.id === e) {
            // 处理info为数组
            if (info.coach_subject !== '') {
              info.coach_subject_arr = info.coach_subject.split(',')
            } else {
              info.coach_subject_arr = []
            }
            _this.info = info
            _this.deleteBtn = true
          }
        })
      }
    },
    loadingAdditional() {
      new Promise((resolve, reject) => {
        userAdditional().then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.student_stage = data.student_stage
        this.coach_subject = data.coach_subject
      })
    },
    coachSubjectHandleChange(e) {
      // 可选提高科目更改
    },
    handleDelStudent(e) {
      // 删除学员
      const contactsId = this.contactsId
      if (contactsId !== '' && contactsId !== 'add') {
        new Promise((resolve, reject) => {
          this.delLoading = true
          userStudentDelete({ id: contactsId }).then(response => {
            if (response.status === 'success') {
              resolve(response)
            } else {
              reject(response)
            }
          })
        }).then(res => {
          this.$notification.success({
            message: res.status,
            description: res.message
          })
          // 发射事件给父组件的监听属性
          this.$emit('refreshData')
        }).catch(error => {
          this.$notification.error({
            message: '错误',
            description: error.message
          })
        }).finally(() => {
          this.delLoading = false
        })
      }
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.relid = this.clientId
          if (values.coach_subject) {
            values.coach_subject = values.coach_subject.join(',')
          } else {
            values.coach_subject = ''
          }
          if (this.contactsId === 'add') {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              userStudentCreate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 发射事件给父组件的监听属性
              this.$emit('refreshData')
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            values.id = this.contactsId
            new Promise((resolve, reject) => {
              this.submitLoading = true
              userStudentSave(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    }
  }
}
</script>
