var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-divider',{attrs:{"orientation":"right"}},[_c('div',[_c('label',[_vm._v("学员：")]),_c('a-select',{staticStyle:{"width":"120px"},attrs:{"default-value":"add"},on:{"change":_vm.handleContactsChange},model:{value:(_vm.contactsId),callback:function ($$v) {_vm.contactsId=$$v},expression:"contactsId"}},[_vm._l((_vm.student),function(option,index){return _c('a-select-option',{key:index,attrs:{"value":option.id}},[_vm._v(_vm._s(option.nick_name)+" ")])}),_c('a-select-option',{attrs:{"value":"add"}},[_vm._v("添加")])],2)],1)]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":10,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"昵称","labelCol":{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'nick_name',
            {
              rules: [{ required: true, message: '昵称' }],
              initialValue: _vm.info.nick_name
            },
          ]),expression:"[\n            'nick_name',\n            {\n              rules: [{ required: true, message: '昵称' }],\n              initialValue: info.nick_name\n            },\n          ]"}],attrs:{"placeholder":"昵称"}})],1)],1),_c('a-col',{attrs:{"lg":14,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别","labelCol":{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sex',
            {
              rules: [{ required: true, message: '请选择性别' }],
              initialValue: _vm.info.sex
            }
          ]),expression:"[\n            'sex',\n            {\n              rules: [{ required: true, message: '请选择性别' }],\n              initialValue: info.sex\n            }\n          ]"}],attrs:{"placeholder":"选择性别"}},[_c('a-select-option',{attrs:{"value":"男"}},[_vm._v("男")]),_c('a-select-option',{attrs:{"value":"女"}},[_vm._v("女")]),_c('a-select-option',{attrs:{"value":"未知"}},[_vm._v("未知")])],1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"学员阶段","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'stage',
        {
          rules: [{ required: true, message: '需要选择学员阶段' }],
          initialValue: _vm.info.stage
        }
      ]),expression:"[\n        'stage',\n        {\n          rules: [{ required: true, message: '需要选择学员阶段' }],\n          initialValue: info.stage\n        }\n      ]"}]},_vm._l((_vm.student_stage),function(option){return _c('a-select-option',{key:option,attrs:{"value":option}},[_vm._v(_vm._s(option))])}),1)],1),_c('a-form-item',{attrs:{"label":"提高科目","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'coach_subject',
          {
            initialValue: _vm.info.coach_subject_arr
          }
        ]),expression:"[\n          'coach_subject',\n          {\n            initialValue: info.coach_subject_arr\n          }\n        ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","token-separators":[',']},on:{"change":_vm.coachSubjectHandleChange}},_vm._l((_vm.coach_subject),function(option){return _c('a-select-option',{key:option,attrs:{"value":option}},[_vm._v(_vm._s(option))])}),1)],1),_c('a-form-item',{attrs:{"label":"学员描述","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'desc',
        {
          initialValue: _vm.info.desc
        }
      ]),expression:"[\n        'desc',\n        {\n          initialValue: info.desc\n        }\n      ]"}],attrs:{"rows":"2","placeholder":"学员基本情况介绍"}})],1),_c('a-form-item',{attrs:{"label":"对教员的要求","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'require_desc',
        {
          initialValue: _vm.info.require_desc
        }
      ]),expression:"[\n        'require_desc',\n        {\n          initialValue: info.require_desc\n        }\n      ]"}],attrs:{"rows":"2","placeholder":"对教员的要求"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),(_vm.deleteBtn)?_c('a-popconfirm',{attrs:{"placement":"rightBottom","title":"确定要删除该学员吗？"},on:{"confirm":_vm.handleDelStudent}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"slot":"icon","type":"question-circle-o"},slot:"icon"}),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"loading":_vm.delLoading,"type":"danger"}},[_vm._v("删除")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }