<template>
  <a-card :bordered="false">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { smsLog } from '@/api/sms'

const columns = [
  {
    title: 'To',
    dataIndex: 'to'
  },
  {
    title: '内容',
    dataIndex: 'message',
    width: '50%'
  },
  {
    title: '返回',
    dataIndex: 'result'
  },
  {
    title: '发送时间',
    dataIndex: 'create_time',
    sorter: true,
    scopedSlots: { customRender: 'create_time' }
  }
]

export default {
  name: 'ClientSms',
  props: {
    clientId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      data: [],
      pagination: {},
      // 查询参数
      queryParam: {},
      loading: false,
      columns
    }
  },
  mounted() {
    this.queryParam = { relid: this.clientId, type: 'user' }
    this.fetch(this.queryParam)
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      // 带上查询参数
      const queryParam = this.queryParam
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        smsLog(params).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        const data = res.data
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.data
        pagination.total = data.total
        pagination.pageSize = data.per_page
        pagination.current = data.current_page
        this.pagination = pagination
      })
    }
  }
}
</script>
